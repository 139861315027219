/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

// ページトップへ戻るのスクロール
  var $pagetop = $('#pageTop');

  if ($(window).width() > 768) $pagetop.hide();
  $(window).scroll(function(){
    if($(window).scrollTop() > 200){
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

  $pagetop.click(function(){
    $('html,body').animate({scrollTop:0},1000);
    return false;
  });


  //この記述はエラーが出る↓↓
// アンカーリンクを押すと、スクロール
  // $('a[href^=#]:not([class*='modal'])').click(function() {
  //     var speed = 400;
  //     var href= $(this).attr("href");
  //     var target = $(href == "#" || href == "" ? 'html' : href);
  //     var position = target.offset().top;
  //     $('body,html').animate({scrollTop:position}, speed, 'swing');
  //     return false;
  // });


});
